import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import styles from '../assets/css/about.module.css';
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter  } from "react-icons/fa";

const About = () => {
   
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "About Us",
      slug: "/about",
      active: false,
    },
  ];

  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"About Us"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent-2">

           <section className={styles.aboutHelpSection}>
               <div className="container">
                  <div className="row mb-5">
                      <div className="col-md-6">
                         <img src="/images/intro-1.png" alt="intro-1" className="img-full"/>
                      </div>
                      <div className="col-md-6">
                         <img src="/images/intro-2.png" alt="intro-1"  className="img-full"/>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-4">
                          <div className={styles.aboutTitle}>
                               <span><img src="/images/heart3.png" alt="img"/> Make a Difference</span>
                              <h2>Let’s help them together.</h2> 
                          </div>
                      </div>
                      <div className="col-md-8">
                        <p>Over the past two years, Maxx Social Welfare Foundation has emerged as a beacon of positive change, leaving a profound impact on the lives of over 10,000 beneficiaries. Founded with a passionate commitment to enhancing holistic well-being, Maxx Social Welfare Foundation has rapidly become a driving force in promoting a healthier, more empowered community. Since its inception, Maxx Social Welfare Foundation has harnessed the collective efforts of a dedicated team of over 60 volunteers spread across eight cities. This enthusiastic team is the backbone of our organization, tirelessly working to bring about transformation and empowerment</p>
                      </div>
                  </div>
                </div>  
           </section>
           <section className={styles.aboutCharitySection}>
           <div className="container-fluid">
                  <div className="row mb-5">
                      <div className="col-md-5">
                        <div className={styles.aboutCharityContent}>
                        <div className={styles.aboutTitle}>
                               <span> Help People Now</span>
                              <h2>Charity for the people you care about..</h2> 
                          </div>
                          <p>What sets Maxx Social Welfare Foundation apart is its innovative approach to community development. Through the implementation of pioneering programs, the organization has successfully introduced novel concepts in areas such as Self Defence, mind sports, and physical literacy. These programs are carefully designed to cater to the diverse needs of our beneficiaries, nurturing essential life skills and fostering personal growth. Our journey began with a simple yet powerful mission: to create a positive impact on individuals, families, and communities. Over the years, this mission has guided us as we've grown, evolved, and extended our reach. Today, Maxx Social Welfare Foundation stands as a testament to the strength of collaborative efforts and a shared vision for a healthier, more empowered society. As we reflect on our journey so far, we are inspired by the lives we have touched, the barriers we have broken, and the potential we have unlocked. Looking ahead, we remain committed to our mission, constantly innovating and striving to make an enduring difference in the lives of those we serve.</p>

                          <div className={styles.charityLabel}>
                              <span>
                                 8860
                              </span>
                              <span>Donation campaigns are running</span>
                          </div>
                        </div>


                      </div>
                      <div className="col-md-7">
                         <div className={styles.charityImg}>
                            <img src="/images/charity-for-the-people.jpg" alt="intro-1"  className="img-full"/>
                         </div>
                        
                      </div>
                  </div>
                
                </div>  
           </section>
           <section className={styles.aboutMissionSection}>
              <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                    <div className={"borderTitle"}>
                        <span>Our Mission</span>
                        <h3>Inspiring <br/> Transformation for <br/>Holistic Well-being</h3>
                        <hr style={{ color: "#fcad30" }} />
                     </div>
                     <p>At Maxx Social Welfare Foundation, our mission is to empower individuals and communities through 
sports, health, education, and personal development initiatives. We are committed to fostering holistic </p><p>
well-being, promoting inclusivity, and creating a positive impact on lives. By delivering innovative 
programs, building strong partnerships, and nurturing essential life skills, we strive to inspire 
transformation and create a brighter future for all.</p>
  <Link to={'/'}>  Donate Now <img src='/images/heart2.png' alt='heart' /></Link>
                    </div>
                    <div className="col-md-6">
                      <div className={styles.abtMissionImg}>
                         <img src="/images/Video.jpg" alt="intro-1"  className="img-full"/>
                      </div>
                     
                    </div>
                  </div>
              </div>
            </section>
       
            <section className={styles.aboutOurValue}>
            <div className="section-title">
              <span>Our Values</span>
              <h2>Guided by Values, Creating Lasting Positive Impact</h2>
              <hr/>
              </div>
              <div className="container-fluid">
              <div className={styles.coreValues}>
                  <div className={styles.coreValueBox}>
                      <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i1.png" alt="icon"/>
                      </div>
                      <h5>Empowerment</h5>
                      <p>We believe in empowering individuals to take charge of their lives, fostering selfconfidence, and enabling them to make informed choices for their well-being. </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i2.png" alt="icon"/>
                      </div>
                      <h5>Inclusivity</h5>
                      <p>We embrace diversity and ensure that our programs cater to individuals of all backgrounds, abilities, and ages, creating an environment where everyone feels valued and included.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i3.png" alt="icon"/>
                      </div>
                      <h5>Innovation</h5>
                      <p>We continuously seek innovative approaches to community development, introducing novel ideas and solutions that address emerging challenges and needs. </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i4.png" alt="icon"/>
                      </div>
                      <h5>Collaboration</h5>
                      <p>Collaboration is at the heart of our efforts. We value partnerships with schools, institutions, volunteers, and supporters to maximize our impact and create a united community.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i5.png" alt="icon"/>
                      </div>
                      <h5>Community Focus</h5>
                      <p>Our efforts are driven by a genuine commitment to the well-being of the community. We prioritize community needs, aspirations, and contributions in all our initiatives.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i6.png" alt="icon"/>
                      </div>
                      <h5>Quality</h5>
                      <p>We are dedicated to delivering high-quality programs that make a meaningful difference. We set high standards for our initiatives to ensure they meet the evolving needs of our beneficiaries. </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i7.png" alt="icon"/>
                      </div>
                      <h5>Respect</h5>
                      <p>We treat all individuals with respect, dignity, and empathy, fostering an environment where everyone's voice is heard and valued.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i8.png" alt="icon"/>
                      </div>
                      <h5>Integrity</h5>
                      <p>We uphold the highest ethical standards in all our endeavors, ensuring transparency, accountability, and honesty in our actions and interactions.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i9.png" alt="icon"/>
                      </div>
                      <h5>Continuous Learning</h5>
                      <p>We are committed to lifelong learning, seeking opportunities to grow, adapt, and improve our programs to better serve our beneficiaries.  </p>
                  </div>
                  <div className={styles.coreValueBox}>
                  <div className={styles.iconWrapper}>
                         <img src="/images/about-icons/i10.png" alt="icon"/>
                      </div>
                      <h5>Positive Impact</h5>
                      <p>Ultimately, our actions are guided by the desire to create a positive and lasting impact on the lives we touch. We measure our success by the transformative outcomes experienced by our beneficiaries</p>
                  </div>
              </div>
              </div>
            
            </section>
            <section className={styles.aboutTeamSection}>
                 <div className="section-title">
                 <span>Our Volunteers</span>
                  <h2>Meet those who help others in need</h2>
                  <hr/>
              </div>
              <div className="container">
              <div className="team">
                <div className="row">
                   <div className="col-md-3">
                   <div className="teamCard">
                      <div className="teamThumb">
                         <img src="/images/team/t1.png" alt="teamImg" className="img-full"/>
                      </div>
                      <div className="teamDetails">
                          <span>Volunteer</span>
                          <h5>Eugene Wells</h5>
                      </div>
                      <ul className="teamSocial">
                      <li><a href="/"><FaTwitter size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaFacebook size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaInstagram size={20} color="#e80404"/></a></li>
                      </ul>
                  </div>
                   </div>
                   <div className="col-md-3">
                   <div className="teamCard">
                      <div className="teamThumb">
                         <img src="/images/team/t2.png" alt="teamImg" className="img-full"/>
                      </div>
                      <div className="teamDetails">
                          <span>Volunteer</span>
                          <h5>Donna Howard</h5>
                      </div>
                      <ul className="teamSocial">
                      <li><a href="/"><FaTwitter size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaFacebook size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaInstagram size={20} color="#e80404"/></a></li>
                      </ul>
                  </div>
                   </div>
                   <div className="col-md-3">
                   <div className="teamCard">
                      <div className="teamThumb">
                         <img src="/images/team/t3.png" alt="teamImg" className="img-full"/>
                      </div>
                      <div className="teamDetails">
                          <span>Volunteer</span>
                          <h5>Donald Henderson</h5>
                      </div>
                      <ul className="teamSocial">
                      <li><a href="/"><FaTwitter size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaFacebook size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaInstagram size={20} color="#e80404"/></a></li>
                      </ul>
                  </div>
                   </div>
                   <div className="col-md-3">
                   <div className="teamCard">
                      <div className="teamThumb">
                         <img src="/images/team/t4.png" alt="teamImg" className="img-full"/>
                      </div>
                      <div className="teamDetails">
                          <span>Volunteer</span>
                          <h5>Elizabeth Walker</h5>
                      </div>
                      <ul className="teamSocial">
                      <li><a href="/"><FaTwitter size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaFacebook size={20} color="#e80404"/></a></li>
                          <li><a href="/"><FaInstagram size={20} color="#e80404"/></a></li>
                      </ul>
                  </div>
                   </div>
                </div>
               
              </div>
              </div>
            
            </section>
    
      
      </div>
      <JoinMission />
       <section className={styles.achievementSection}>
             <div className="section-title">
                  <h2>Achievements and impact</h2>
                  <hr/>
              </div>
              <div className="container">
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                           <div className={styles.achievementItemTitle}>
                            <h5>Empowering Over 10,000 Beneficiaries</h5>
                            <hr/>
                           </div>
                           <p>Through our innovative programs, Maxx Social Welfare Foundation has directly impacted the lives of more than 10,000 beneficiaries. Our reach extends across diverse demographics, touching individuals of varying ages, backgrounds, and abilities. Witnessing the transformational growth of these beneficiaries fuels our motivation to continue making a difference</p>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bg1.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              <div className={styles.achievementItems2}>
                 <div className="row align-items-center">
                   
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bg2.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                      <div className="col-md-7 text-right">
                        <div className={styles.achievementItemContent}>
                           <div className={styles.achievementItemTitle}>
                            <h5>A Network of 60+ Volunteers in 8 Cities</h5>
                            <hr/>
                           </div>
                           <p>The heart and soul of Maxx Social Welfare Foundation lies within its dedicated team of over 60 volunteers, spread across eight cities. These passionate individuals form a tight-knit community united by a shared vision. Their selfless contributions have enabled us to deliver programs and services that leave an indelible mark on our beneficiaries. </p>
                        </div>
                     </div>
                 </div>
              </div>
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                           <div className={styles.achievementItemTitle}>
                            <h5>Innovative Programs Shaping Lives</h5>
                            <hr/>
                           </div>
                           <p>At the core of our impact lies our commitment to introducing innovative programs. Our initiatives in areas such as Self Defence, mind sports, and physical literacy have redefined the way we approach community development. These programs serve as vehicles for growth, equipping beneficiaries with essential life skills, boosting confidence, and fostering empowerment. </p>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bg3.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              <div className={styles.achievementItems2}>
                 <div className="row align-items-center">
                   
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bg4.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                      <div className="col-md-7 text-right">
                        <div className={styles.achievementItemContent}>
                           <div className={styles.achievementItemTitle}>
                            <h5>Breaking Barriers and Inspiring Growth</h5>
                            <hr/>
                           </div>
                           <p>[NGO Name]'s journey has been defined by our ability to break barriers and inspire growth. We take pride in enabling individuals to overcome challenges, discover their potential, and unlock new opportunities. Through our programs, we have witnessed stories of personal triumph, where beneficiaries have emerged stronger, more confident, and ready to embrace their potential.  </p>
                        </div>
                     </div>
                 </div>
              </div>
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                           <div className={styles.achievementItemTitle}>
                            <h5>A Bright Future Ahead</h5>
                            <hr/>
                           </div>
                           <p>As we reflect on our achievements and their impact, we are motivated to continue our journey with renewed vigor. The lives touched, the barriers shattered, and the potential realized serve as beacons guiding us forward. With each milestone, Maxx Social Welfare Foundation reinforces its commitment to building a healthier, empowered, and united community, setting the stage for a brighter future for all. </p>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bg5.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              </div>
         
       </section>
    </Fragment>
  );
};

export default About;
