import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";

const Program = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Programs",
      slug: "/programs",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Programs"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
        <div className="container">
          <div className="ttl-sec">
            <h1 className="text-center">
              Fostering Holistic Well-being  Through Diverse Programs
            </h1>
            <p className="text-center">
              At Maxx Social Welfare Foundation, our diverse range of programs
              reflects our commitment to fostering holistic well-being,
              promoting inclusivity, and creating a positive impact on
              individuals and communities. Through innovative initiatives, we
              empower, educate, and inspire personal growth in various domains
            </p>
          </div>

          <section className="pdt-50">
            <div className="innerTitle">
              <h3>Sports Programs</h3>
              <span className={"borderBottom"}></span>
            </div>
            <div className="row">
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                        <img src="/images/sp-1.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Youth Sports Leagues</h5>
                             <p>Engaging young minds in healthy competition, teamwork, and physical activity through organized sports leagues. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                        <img src="/images/sp-2.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Sports Clinics and Workshops</h5>
                             <p>Providing specialized training and workshops to enhance sports skills, technique, and game strategies </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                        <img src="/images/sp-3.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Adaptive Sports Programs</h5>
                             <p>Creating opportunities for individuals with disabilities to participate in sports, fostering inclusivity and boosting self-confidence.</p>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="pdt-50">
            <div className="innerTitle">
              <h3>Health Programs</h3>
              <span className={"borderBottom"}></span>
            </div>
            <div className="row">
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/hp-1.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Health Education Seminars</h5>
                             <p>Offering informative sessions on various health topics, empowering individuals with knowledge to make informed lifestyle choices. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/hp-2.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Wellness Campaigns</h5>
                             <p>Conducting awareness campaigns that promote overall wellness, mental health, and preventive care. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/hp-3.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Nutrition and Fitness Programs</h5>
                             <p>Educating participants about balanced nutrition and delivering fitness programs that encourage active and healthy living.</p>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="pdt-50">
            <div className="innerTitle">
              <h3>Physical Education</h3>
              <span className={"borderBottom"}></span>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/pe-1.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>School Outreach for Physical Education Enhancement</h5>
                             <p>Collaborating with schools to enhance physical education curricula, promoting active lifestyles and overall well-being among students. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/pe-2.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Training for Physical Education Teachers</h5>
                             <p>Providing professional development and training to physical education teachers, equipping them with modern pedagogical approaches.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/pe-3.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Sports Equipment Donation Initiatives</h5>
                             <p>Facilitating the donation of sports equipment to schools and communities, ensuring access to essential resources for sports and physical activities.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/pe-4.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Self Defence Programs</h5>
                             <p>Empowering individuals with the essential skills and knowledge for self-defense, enhancing personal safety and self-confidence. Mind </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-15">
                    <div className="cardBox">
                        <div className="cardThumb">
                          <img src="/images/pe-5.jpg" alt="img"/>
                        </div>
                        <div className="cardBody">
                            <h5>Sports Programs</h5>
                             <p>Offering engaging activities that stimulate cognitive abilities, critical thinking, and strategic planning, contributing to well-rounded personal growth.</p>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="pdt-50">
         
            <div className="row justify-content-center">
                <div className="col-md-6 mb-15">
                   <div className="imgBox p-20">
                      <img src="/images/htr.png" alt="img"/>
                   </div>
                </div>
                <div className="col-md-6 mb-15">
                <div className={'borderTitle'}>
                     <span>Partnerships</span>
                     <h3>Building Strong Partnerships for Positive Change</h3>
                     <hr style={{ color: '#fcad30' }} />
                </div>
                 <p>Maxx Social Welfare Foundation thrives on partnerships, joining hands with schools, institutions, and NGOs to maximize our impact. We welcome sponsors, supporters, and corporate collaborations to help us empower individuals and create positive change</p>
                 <div className="progressBar">
                     <div className="progressItem">
                       <div className="progressLabel">
                         <p>Collaborations with schools, institutions, and other NGOs</p>
                         <span>65%</span>
                       </div>
                       <div className="progressStatus">
                          <div className="progressInnerStatus" style={{width:'65%'}}></div>
                       </div>
                     </div>
                     <div className="progressItem">
                       <div className="progressLabel">
                         <p>Sponsors and supporters</p>
                         <span>80%</span>
                       </div>
                       <div className="progressStatus">
                          <div className="progressInnerStatus" style={{width:'80%'}}></div>
                       </div>
                     </div>
                     <div className="progressItem">
                       <div className="progressLabel">
                         <p>Opportunities for corporate partnerships</p>
                         <span>70%</span>
                       </div>
                       <div className="progressStatus">
                          <div className="progressInnerStatus" style={{width:'70%'}}></div>
                       </div>
                     </div>
                 </div>
                 <a className="vlBtn" href="https://docs.google.com/forms/d/e/1FAIpQLSexluXZb2_7qkUYPGv2CZFT4pOJFXpqFBtzcNuIqrek1LaVrQ/viewform?usp=sf_link" target="_black" rel="noreferrer"> Become a volunteer <img src='/images/heart2.png' alt='heart' /></a>
                </div>
             
            </div>
          </section>
          <p className="bt-text">Through these diverse programs, Maxx Social Welfare Foundation endeavors to create an environment where individuals can thrive physically, mentally, and emotionally. Our commitment to education, empowerment, and enrichment drives us to continuously innovate and expand our offerings, creating lasting positive impact across various spheres of life.</p>

          
        </div>
      </div>
      <JoinMission />
    </Fragment>
  );
};

export default Program;
