import React,{useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FaHeart  } from "react-icons/fa";
const Header = () => {
    const [isOpen, setOpen] = useState(false);


     useEffect(() => {
            window.addEventListener('scroll', isSticky);
            return () => {
                window.removeEventListener('scroll', isSticky);
            };
        });

       const isSticky = (e) => {
                    const header = document.querySelector('.header-bottom');
                    const scrollTop = window.scrollY;
                    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
                };
  return (
     <header>
          <div className='header-top'>
             <p> Help us make a change -  <a href="https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform?usp=sf_link"  target="_black" rel="noreferrer">Partner with Us</a> </p>
          </div>
          <div className='header-bottom'>
            <div className='container'>
            <div className='brand-name'>
                  <Link to={'/'}>
                     <img src='/images/logo.png' alt='logo'/>
                  </Link>
             </div>
             <div className={`navigation ${isOpen ? 'open' : ''}`}>
                  <nav>
                     <ul>
                         <li><Link to="/"> Home</Link></li>
                         {/* <li><Link to="/about"> About</Link></li>
                         <li><Link to="/programs"> Programs</Link></li> */}
                         <li><a href="https://www.linkedin.com/company/maxx-social-welfare-foundation/" target='_blank' rel="noreferrer">Careers</a></li>
                         {/* <li><Link to="/events">Events</Link></li> */}
                         <li><Link to="/gallery">Gallery</Link></li>
                         <li><a href="https://maxxsocialwelfare.medium.com/" target='_blank' rel="noreferrer">Blog</a></li>
                         <li><Link to="/contact-us">Contact Us</Link></li>
                     </ul>
                  </nav>
              </div>
              <div className='header-actions'>
                 <a className="donate-btn" href={'https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform'} target='_blank' rel="noreferrer">Partner With Us 
                  {/* <img src='/images/heart.png' alt='heart' /> */}
                  <FaHeart/>
                  </a>
                 <button className={`m-btn menuToogle  ${isOpen ? 'open' : ''}`} onClick={()=>setOpen(!isOpen)}>
                      <span></span>
                      <span></span>
                      <span></span>
                   </button>
              </div>
            </div>
            
          </div>
     </header>
  )
}

export default Header