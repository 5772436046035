import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import styles from "../assets/css/contact.module.css";

const Contact = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Contact Us",
      slug: "/contact-us",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Contact Us"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className={"innerPageContent"}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 pd-20">
              <div className={styles.getInTouch}>
                <div className={"borderTitle"}>
                  <span>Contact Us</span>
                  <h3>Get in Touch</h3>
                  <hr style={{ color: "#fcad30" }} />
                </div>
                {/* <p className="mb-15">
                  Nec sagittis aliquam malesuada bibendum arcu vitae elementum
                  curabitur vitae. Nibh sit amet commodo nulla facilisi.
                </p> */}
                <div className={styles.contactSocials}>
                  <ul className="contactList">
                    <li className="contactListItem mb-15">
                      <div className="fIcon">
                        <FiPhone color="#fff" size={22} />
                      </div>
                      <p>
                        <strong>Call us</strong>
                        <br />
                        +91 70459 54450
                      </p>
                    </li>
                    <li className="contactListItem mb-15">
                      <div className="fIcon">
                        <FiMail color="#fff" size={22} />
                      </div>
                      <p>
                        <strong>Mail Us</strong>
                        <br />
                        info@maxxsocialwelfare.org
                      </p>
                    </li>
                    <li className="contactListItem mb-15">
                      <div className="fIcon">
                        <FiMapPin color="#fff" size={22} />
                      </div>
                      <p>
                        <strong>Visit Us</strong>
                        <br />
                        Near Chip and Dale School, Patanwala Estate, Lal Bahadur
                        Shastri Marg, opp. Shreyas Cinema, Ghatkopar West,
                        Mumbai, Maharashtra 400086
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 pd-20">
              <div className={styles.contactForms}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                  <Nav
                    variant="pills"
                    className={`${styles.formTabLinks} flex-row`}
                  >
                    <Nav.Item className={styles.formTabLinkItems}>
                      <Nav.Link eventKey="first">Give Donation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={styles.formTabLinkItems}>
                      <Nav.Link eventKey="second">Be A Volunteer</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={styles.formTabLinkItems}>
                      <Nav.Link eventKey="three">Be a Member</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">

                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                       <div className={styles.contactFormWrapper}>
                            <h3>Fill the Form Below</h3>
                            <form className={styles.contactForm}>
                                <div className="mb-15 row">
                                    <div className="col-md-6">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Your Name"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Age</label>
                                        <input type="text" className="form-control" placeholder="Your Age"/>
                                    </div>
                                </div>
                                <div className="mb-15 row">
                                    <div className="col-md-6">
                                        <label>Phone</label>
                                        <input type="tel" className="form-control" placeholder="Your Phone"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Volunteer Category *</label>
                                        <select className="form-control">
                                           <option value={''}>---Select---</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-15 row">
                                    <div className="col-md-6">
                                        <label>Occupation</label>
                                        <input type="text" className="form-control" placeholder="Occupation"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Location</label>
                                        <input type="text" className="form-control" placeholder="Location"/>
                                    </div>
                                </div>
                                <div className="mb-15 row">
                                    <div className="col-md-12">
                                        <label>Message</label>
                                        <textarea className="form-control" placeholder="Enter your message"/>
                                    </div>
                                </div>
                                <button type="submit">Send Message</button>
                            </form>
                       </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="three">

                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div> */}
            <div className="col-md-6 pd-20">
              <div className={styles.mapSection}>
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.3303755746538!2d72.9121700743343!3d19.093157151435356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b85655555555%3A0x95dc3a630640b911!2sChip%20and%20Dale%20Preschool%20Ghatkopar!5e0!3m2!1sen!2sin!4v1695379331331!5m2!1sen!2sin"
                  width={"100%"}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <JoinMission />
    </Fragment>
  );
};

export default Contact;
