import React from 'react'
import styles from '../../assets/css/modal.module.css'
import { MdClose } from "react-icons/md";

const MyModal = ({isOpen, closeModal, children}) => {
    if (!isOpen) return null;
  return (
    <div onClick={closeModal} className={styles.modalWrapper}>
      <button className={styles.closeButton} onClick={closeModal}>
         <MdClose/>
      </button>
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.modalContainer}>
        
         {children}
    </div>
  </div>
  )
}

export default MyModal