import React from 'react'
import styles from '../../assets/css/maintenance.module.css'
import { Link } from 'react-router-dom'
const PageNotFound = () => {
  return (
    <div className={styles.errorWrapper}>
           <div className={styles.box1}>
                <img src='/images/opps.png' alt='opps'/>
           </div>
           <div className={styles.box2}>
              <h1>404</h1>
              <h3>Page Not Found</h3>
              <Link to="/" className='button-1'>Back to Home</Link>
           </div>
    </div>
  )
}

export default PageNotFound