import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import styles from '../assets/css/about.module.css';
import { FiCalendar, FiMapPin  } from "react-icons/fi";

const Events = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Events",
      slug: "/events",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Events"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
      <section className={styles.achievementSection}>
          
              <div className="container">
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                            <div className={styles.eventTiming}>
                                <ul>
                                    <li>
                                         <FiCalendar/> <span><span className={styles.dateData}>20/06/2022</span>   11AM to 15PM</span>
                                    </li>
                                    <li>
                                         <FiMapPin/> <span> 8502 Preston Rd. Inglewood, Maine 98380</span>
                                    </li>
                                </ul>
                            </div>
                           <div className={styles.achievementItemTitle}>
                            <h5>Empowering Over 10,000 Beneficiaries</h5>
                            <hr className={styles.mbt15}/>
                           </div>
                           <p>Through our innovative programs, Maxx Social Welfare Foundation has directly impacted the lives of more than 10,000 beneficiaries. Our reach extends across diverse demographics, touching individuals of varying ages, backgrounds, and abilities. Witnessing the transformational growth of these beneficiaries fuels our motivation to continue making a difference</p>
                            <div className={styles.organizerDetails}>
                                  <img src="/images/org.png" alt="img"/>
                                  <p>Organized By : <span>Maxx Social Welfare</span></p>
                            </div>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bgg1.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                            <div className={styles.eventTiming}>
                                <ul>
                                    <li>
                                         <FiCalendar/> <span><span className={styles.dateData}>20/06/2022</span>   11AM to 15PM</span>
                                    </li>
                                    <li>
                                         <FiMapPin/> <span> 8502 Preston Rd. Inglewood, Maine 98380</span>
                                    </li>
                                </ul>
                            </div>
                           <div className={styles.achievementItemTitle}>
                            <h5>Empowering Over 10,000 Beneficiaries</h5>
                            <hr className={styles.mbt15}/>
                           </div>
                           <p>Through our innovative programs, Maxx Social Welfare Foundation has directly impacted the lives of more than 10,000 beneficiaries. Our reach extends across diverse demographics, touching individuals of varying ages, backgrounds, and abilities. Witnessing the transformational growth of these beneficiaries fuels our motivation to continue making a difference</p>
                            <div className={styles.organizerDetails}>
                                  <img src="/images/org.png" alt="img"/>
                                  <p>Organized By : <span>Maxx Social Welfare</span></p>
                            </div>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bgg2.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              <div className={styles.achievementItems}>
                 <div className="row align-items-center">
                     <div className="col-md-7">
                        <div className={styles.achievementItemContent}>
                            <div className={styles.eventTiming}>
                                <ul>
                                    <li>
                                         <FiCalendar/> <span><span className={styles.dateData}>20/06/2022</span>   11AM to 15PM</span>
                                    </li>
                                    <li>
                                         <FiMapPin/> <span> 8502 Preston Rd. Inglewood, Maine 98380</span>
                                    </li>
                                </ul>
                            </div>
                           <div className={styles.achievementItemTitle}>
                            <h5>Empowering Over 10,000 Beneficiaries</h5>
                            <hr className={styles.mbt15}/>
                           </div>
                           <p>Through our innovative programs, Maxx Social Welfare Foundation has directly impacted the lives of more than 10,000 beneficiaries. Our reach extends across diverse demographics, touching individuals of varying ages, backgrounds, and abilities. Witnessing the transformational growth of these beneficiaries fuels our motivation to continue making a difference</p>
                            <div className={styles.organizerDetails}>
                                  <img src="/images/org.png" alt="img"/>
                                  <p>Organized By : <span>Maxx Social Welfare</span></p>
                            </div>
                        </div>
                     </div>
                     <div className="col-md-5">
                          <div className={styles.achievementItemThumb}>
                             <img src="/images/events/bgg3.png" alt="teamImg" className="img-full"/>
                             <span>Free</span>
                          </div>
                      </div>
                 </div>
              </div>
              </div>
              </section>
      </div>
      <JoinMission />
   
    </Fragment>
  );
};

export default Events;
