import React from 'react'
import styles from '../../assets/css/home.module.css'
const JoinMission = () => {
  return (
    <div className={styles.joinMission}
    style={{
      backgroundImage: `url('/images/joinBg.jpg')`,
    }}
  >
    <div className="container">
      <div className={styles.joinMissionInner}>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="section-title-2">
              <span>Do Good</span>
              <h2 className="text-white">Join Our Mission to Improve the World</h2>
              <hr />
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.joinButtonGroup}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSc69uIzIXehks6PQDVVKW2sJQNILyBzPl3zbzTc5zqLt7sSvg/viewform" target="_black" rel="noreferrer"> Partner with Us <img src='/images/heart2.png' alt='heart' /></a>
              <a href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSexluXZb2_7qkUYPGv2CZFT4pOJFXpqFBtzcNuIqrek1LaVrQ%2Fviewform%3Fusp%3Dsf_link&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSexluXZb2_7qkUYPGv2CZFT4pOJFXpqFBtzcNuIqrek1LaVrQ%2Fviewform%3Fusp%3Dsf_link&ifkv=AYZoVhdWMvv91lv7odYtmmiZBcBc-Fc4Y7qwOaQsoOPxP9bWTQp9ajRFvsGsCtGGXEAOnyWm2iPGfA&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S-1190157909%3A1694517703787655&theme=glif" target="_black" rel="noreferrer">  Volunteer With Us  <img src='/images/heart2.png' alt='heart' /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  )
}

export default JoinMission