import React, { Fragment,useState } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import GalleryThumbnail from "../components/common/GalleryThumbnail";
import MyModal from "../components/common/MyModal";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
const GalleryInner = () => {
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [lightboxOpen, setLightboxOpen] = useState(false);

   const openLightbox = (index) => {
      setSelectedIndex(index);
      setLightboxOpen(true);
    };
  
    const closeLightbox = () => {
      setSelectedIndex(0);
      setLightboxOpen(false);
    };
  
    const goToPrevious = () => {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    };
  
    const goToNext = () => {
      if (selectedIndex < photos.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    };
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Gallery",
      slug: "/gallery",
      active: false,
    },
    {
      name: "Sports Event 2020",
      slug: "/gallery",
      active: false,
    },
  ];

  const photos = [
   {
     src: '/images/gallery/g-2.jpg',
     caption: 'Image 1',
   },
   {
      src: '/images/gallery/g-3.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-13.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-16.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-17.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-18.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-19.jpg',
      caption: 'Image 1',
    },
    {
      src: '/images/gallery/g-20.jpg',
      caption: 'Image 1',
    },  {
      src: '/images/gallery/g-2.jpg',
      caption: 'Image 1',
    },
    {
       src: '/images/gallery/g-3.jpg',
       caption: 'Image 1',
     },
     {
       src: '/images/gallery/g-13.jpg',
       caption: 'Image 1',
     },
     {
       src: '/images/gallery/g-16.jpg',
       caption: 'Image 1',
     },
 
 ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Sports Event 2020"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
      {/* <Gallery photos={photos}/> */}
        <div className="container">
             <div className="gallery">
               <div className="row">
               {photos.map((photo, index) => (
                  <div className="col-md-3">
                    <GalleryThumbnail
                       key={index}
                       index={index}
                        photo={photo}
                        openLightbox={openLightbox}
                      />
                  </div>
                  ))}
               </div>
               
              
      </div>
       
        </div>
        <MyModal isOpen={lightboxOpen} closeModal={closeLightbox}>
        
        <div className={'galleryControls'}>
          <button onClick={goToPrevious} className="galleryBtnPrev"><FiArrowLeft/></button>
           <button onClick={goToNext} className="galleryBtnNext"><FiArrowRight/></button>
        </div>
     
         <img src={photos[selectedIndex].src} alt={photos[selectedIndex].caption} className="galleryImg" />
     </MyModal>
      
      </div>
      <JoinMission />
    </Fragment>
  );
};

export default GalleryInner;
